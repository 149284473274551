import styled, { css } from 'styled-components';
import Link from 'next/link';
import { Colors, FontSizePx } from '../../../dls/Styleguide';

export const FooterContainer = styled.footer`
  background-color: ${Colors.DarkGray};
  min-height: 112px;
  max-height: 221px;
  display: flex;
  gap: 40px;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  padding: 0 80px;

  @media all and (max-width: 45em) {
    padding: 24px 16px;
    flex-wrap: wrap;
    row-gap: 24px;
  }
`;

export const TopDivider = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border-top: 1px solid ${Colors.CardAgencyGray};
  width: 100%;
`;

export const FlexWrapper = styled.div<{ $mobileStretch?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;

  @media all and (max-width: 45em) {
    gap: 16px;
    ${(props) =>
      props.$mobileStretch &&
      css`
        width: 100%;
        justify-content: space-between;
      `}
  }

  @media all and (max-width: 20em) {
    flex-direction: column;
    align-items: start;
  }
`;

export const FooterInfo = styled.p`
  color: ${Colors.LightGray};
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
`;

export const FooterLink = styled(Link)`
  font-size: ${FontSizePx.BodySmall};
  color: ${Colors.SilverDark};
  letter-spacing: 0.5px;
  font-weight: 450;
  line-height: 20px;
  text-decoration: underline;
  transition: color 0.2s;
  white-space: break-spaces;
  text-align: center;

  &:hover {
    color: ${Colors.Beige} !important;
  }
`;
