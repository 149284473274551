export const TranslatedStrings = {
  is: {
    footer: {
      audurPartOfKvika: 'Auður er vörumerki Kviku banka hf.',
      termsAndConditions: 'Skilmálar',
      frequentlyAskedQuestions: 'Algengar spurningar',
      contactUs: 'Hafa samband',
    },
  },
};
