import * as React from 'react';
import { NumberFormatValues } from 'react-number-format';

import Input, { NumberFormatCurrencyInput, NumberFormatPhoneNumber, NumberFormatSSN } from '@kvika/audur-input';
import { sanitiseSSN } from '@kvika/string-utils';

import { FontSizePx } from '../../dls/Styleguide';
import { ignoreTab } from '../../utils/Utils';

type NativeInputProps = React.InputHTMLAttributes<HTMLInputElement>;
type Props = {
  id?: string;
  label?: string;
  required?: boolean;
  defaultValue?: NativeInputProps['defaultValue'];
  style?: React.CSSProperties;
  placeholder?: NativeInputProps['placeholder'];
  error?: boolean;
  errorText?: string;
  type?: NativeInputProps['type'];
  onFocus?: NativeInputProps['onFocus'];
  onSubmit?: NativeInputProps['onSubmit'];
  autoFocus?: NativeInputProps['autoFocus'];
  onBlur?: () => void;
  shouldIgnoreTab?: boolean;
  isDisabled?: boolean;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  isPhoneNumber?: boolean;
  isSSN?: boolean;
  isCurrency?: boolean;
  onChange: (value: string) => void;
  name: string;
  width: string;
  value?: string | number;
  helperText?: string;
  maxLength?: NativeInputProps['maxLength'];
  className?: string;
  showCharacterCounter?: boolean;
  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
  showTooltip?: boolean;
  tooltipText?: string;
  tooltipIcon?: React.ReactNode;
  ssnOnlyNumbers?: boolean;
};

const AudurTextInput = React.forwardRef(
  (
    {
      id,
      label,
      required,
      defaultValue,
      placeholder,
      style,
      error,
      errorText,
      type,
      onFocus,
      onSubmit,
      autoFocus,
      onBlur,
      shouldIgnoreTab,
      isDisabled,
      onPaste,
      name,
      width,
      onChange,
      value,
      helperText,
      maxLength,
      isPhoneNumber,
      isSSN,
      isCurrency,
      className,
      showCharacterCounter,
      onKeyDown,
      showTooltip,
      tooltipText,
      tooltipIcon,
      ssnOnlyNumbers,
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const maxAllowedValue = 200000000;
    const [showError, setShowError] = React.useState(false);

    const onEnter = (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        (event.target as HTMLElement).blur();
      }
    };

    if (isPhoneNumber) {
      return (
        <NumberFormatPhoneNumber
          className={className}
          width={width}
          label={label}
          value={value}
          id={id}
          onKeyUp={onEnter}
          onChange={(e) => onChange(e.target.value)}
          name={name}
          autoFocus={autoFocus}
          ref={ref}
          onKeyDown={(event) => {
            if (shouldIgnoreTab) {
              ignoreTab(event);
            }
            onKeyDown && onKeyDown(event);
          }}
          disabled={isDisabled}
        />
      );
    }

    if (isSSN) {
      return (
        <NumberFormatSSN
          className={className}
          value={value}
          width={width}
          label={label}
          name={name}
          id={id}
          error={showError && error}
          errorText={errorText}
          onKeyUp={onEnter}
          onChange={(e) => onChange(ssnOnlyNumbers ? sanitiseSSN(e.target.value) : e.target.value)}
          onFocus={(e) => {
            onFocus && onFocus(e);
            setShowError(true);
          }}
          onBlur={() => {
            onBlur && onBlur();
            setShowError(true);
          }}
          autoFocus={autoFocus}
          ref={ref}
          onKeyDown={(event) => {
            if (shouldIgnoreTab) {
              ignoreTab(event);
            }
            onKeyDown && onKeyDown(event);
          }}
          disabled={isDisabled}
        />
      );
    }

    if (isCurrency) {
      return (
        <NumberFormatCurrencyInput
          className={className}
          label={label}
          onChange={(e) => onChange(e.target.value)}
          name={name}
          value={value}
          onBlur={onBlur}
          ref={ref}
          style={style}
          helperText={helperText}
          id={id}
          onKeyUp={onEnter}
          width={width}
          maxLength={maxLength}
          placeholder={placeholder}
          getIsAllowedValue={(values: NumberFormatValues) => {
            const { floatValue } = values;
            if (floatValue === undefined) {
              return true;
            }
            return floatValue >= 0 && floatValue <= maxAllowedValue;
          }}
          autoFocus={autoFocus}
        />
      );
    }

    return (
      <Input
        width={width}
        placeholder={placeholder}
        id={id}
        label={label}
        type={type}
        error={showError && error}
        errorText={errorText}
        required={required}
        defaultValue={defaultValue}
        style={style}
        onPaste={onPaste}
        onSubmit={onSubmit}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setShowError(true);
        }}
        onBlur={() => {
          onBlur && onBlur();
          setShowError(true);
        }}
        onKeyUp={onEnter}
        onKeyDown={(event) => {
          if (shouldIgnoreTab) {
            ignoreTab(event);
          }
          onKeyDown && onKeyDown(event);
        }}
        autoFocus={autoFocus}
        disabled={isDisabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        maxLength={maxLength}
        ref={ref}
        className={className}
        labelFontSize={FontSizePx.Body}
        showCharacterCounter={showCharacterCounter}
        showTooltip={showTooltip}
        tooltipText={tooltipText}
        tooltipIcon={tooltipIcon}
      />
    );
  }
);
AudurTextInput.displayName = 'AudurTextInput';

export default AudurTextInput;
