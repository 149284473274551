// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from 'react';

const canUseDOM = (): boolean => {
  if (typeof window === 'undefined' || !window.document || !window.document.createElement) {
    return false;
  }
  return true;
};

const CookieHubLoader = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [cookieHub, setCookieHub] = useState(undefined);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typeof window?.cookiehub !== 'undefined' && window.cookiehub !== cookieHub) {
        setCookieHub(window.cookiehub);
      }
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Clean up on unmount
  }, [cookieHub]);

  useEffect(() => {
    if (canUseDOM() && !hasLoaded && typeof cookieHub?.load === 'function') {
      const cpm = {};
      cookieHub.load(cpm);
      setHasLoaded(true);
    }
  }, [hasLoaded, cookieHub]); // Rerun when `hasLoaded` or `cookieHub` changes

  return null;
};

export default CookieHubLoader;
